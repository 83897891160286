import * as React from 'react'
import { Cell, Row, Table } from '@toasttab/buffet-pui-table'
import { t } from '@local/translations'
import { FeinsWithBenefitsCardProps } from '../FeinsWithBenefitsCard'
import { ManageBenefitsButton } from './ManageBenefitsButton'

export const FeinWithBenefitsTableSmallScreen: React.FunctionComponent<
  FeinsWithBenefitsCardProps
> = ({ feins }) => {
  return (
    <Table variant='summary-card'>
      <tbody>
        {feins.map((fein) => {
          return (
            <Row key={`fein-without-benefits-${fein.id}`}>
              <Cell className='grid gap-y-2 justify-items-start'>
                <div className='font-semibold'>{fein.name}</div>
                <div className='text-secondary'>
                  {t('group-health.benefits.fein', { fein: fein.fein })}
                </div>
                <ManageBenefitsButton />
              </Cell>
            </Row>
          )
        })}
      </tbody>
    </Table>
  )
}
