import * as React from 'react'
import {
  CustomerContextCustomer,
  CustomerContextProvider
} from '@local/contexts'
import {
  UserContextProvider,
  UserContextUser
} from '@local/contexts/src/user-context'
import { useFeature } from '@toasttab/ec-features'

type AppContextProps = {
  user: UserContextUser
}

/** Make available all the shared app data such as the customer data **/
export const AppContext: React.FunctionComponent<
  React.PropsWithChildren<AppContextProps>
> = ({ children, user }) => {
  const [customerContextValue, setCustomerContextValue] = React.useState<
    CustomerContextCustomer | undefined
  >(() => undefined)

  const [userContextValue] = React.useState<UserContextUser>(() => user)
  const hasVestwellFeeatureFlag = useFeature(
    'ec-pcard-show-vestwell-tile',
    false
  )
  const hasSimplyInsuredFeatureFlag = useFeature(
    'ec-pcard-simply-insured',
    false
  )

  // todo pcard-2349 load entitlements and module data
  React.useEffect(() => {
    setCustomerContextValue({
      hasPayrollPro: true,
      hasBenefitsModule: true,
      hasAcaModule: true,
      hasVestwellRetirementPlanOption: hasVestwellFeeatureFlag,
      hasSimplyInsuredBenefitsOption: hasSimplyInsuredFeatureFlag
    })
  }, [hasSimplyInsuredFeatureFlag, hasVestwellFeeatureFlag])

  return customerContextValue ? (
    <CustomerContextProvider value={customerContextValue}>
      <UserContextProvider value={userContextValue}>
        {children}
      </UserContextProvider>
    </CustomerContextProvider>
  ) : null
}
