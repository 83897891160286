import * as React from 'react'
import cx from 'classnames'
import { useDisclosure } from './useDisclosure'

export interface DisclosureContentProps {
  children: React.ReactNode
  unmountOnClose?: boolean
  className?: string
  ariaLabeledBy?: string
}

export const DisclosureContent = ({
  children,
  unmountOnClose,
  className,
  ariaLabeledBy
}: DisclosureContentProps) => {
  const { isOpen, testId, id } = useDisclosure()

  // This needs to match the `duration-300` on the container below
  const state = useTransitionState(isOpen, 300)

  const childrenRef = React.useRef<HTMLDivElement>(null)

  if (unmountOnClose && state === 'closed') {
    return null
  }

  return (
    <div
      aria-labelledby={ariaLabeledBy}
      aria-hidden={!isOpen}
      id={`${id}-content`}
      data-testid={`${testId}-content`}
      className={cx('transition-all duration-300 ease-in-out', {
        'overflow-hidden': state !== 'open',
        invisible: state === 'closed'
      })}
      style={{
        maxHeight: getMaxHeight(state, childrenRef.current?.scrollHeight)
      }}
    >
      <div ref={childrenRef} className={className}>
        {children}
      </div>
    </div>
  )
}

type TransitionState =
  | 'pre-opening'
  | 'opening'
  | 'open'
  | 'pre-closing'
  | 'closing'
  | 'closed'

const useTransitionState = (isOpen: boolean, timeout: number) => {
  const [state, setState] = React.useState<TransitionState>(
    isOpen ? 'open' : 'closed'
  )

  React.useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null

    if (isOpen) {
      if (!state.includes('open')) {
        setState('pre-opening')
      } else if (state === 'pre-opening') {
        setState('opening')
      } else if (state === 'opening') {
        timeoutId = setTimeout(() => {
          setState('open')
        }, timeout)
      }
    } else {
      if (state.includes('open')) {
        setState('pre-closing')
      } else if (state === 'pre-closing') {
        setState('closing')
      } else if (state === 'closing') {
        timeoutId = setTimeout(() => {
          setState('closed')
        }, timeout)
      }
    }

    return () => {
      timeoutId && clearTimeout(timeoutId)
    }
  }, [state, isOpen, timeout])

  return state
}

const getMaxHeight = (state: TransitionState, scrollHeight?: number) => {
  switch (state) {
    case 'opening':
    case 'pre-closing':
      return scrollHeight === undefined ? undefined : `${scrollHeight}px`
    case 'closing':
    case 'closed':
    case 'pre-opening':
      return '0px'
    default:
      return undefined
  }
}
