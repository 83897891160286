export default {
  done: {
    message: 'Done',
    description: 'Label indicating that the step has completed'
  },
  active: {
    message: 'Active',
    description: 'Label indicating that the step is currently active'
  },
  inactive: {
    message: 'Inactive',
    description: 'Label indicating that the step is currently inactive'
  },
  error: {
    message: 'Error',
    description: 'Label indicating that the step has experienced an error'
  }
}
