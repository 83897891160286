import { ApolloClient, InMemoryCache } from '@apollo/client'

export const getClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Customer: {
          keyFields: ['uuid']
        }
      }
    }),
    connectToDevTools: true,
    name: process.env.SPA_NAME,
    version: process.env.PKG_VERSION,
    uri: '/graphql'
  })
}
