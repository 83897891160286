import * as React from 'react'
import { BanquetProps } from 'banquet-runtime-modules'
import { ApolloProvider } from '@apollo/client'
import { EcProps } from '@toasttab/ec-banquet-props'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { LockLockedIcon } from '@toasttab/buffet-pui-icons'
import { t } from '@local/translations'
import { getClient } from '@local/api/client'
import { AppRouter } from './AppRouter'
import { AppContext } from './AppContext'

export type AppProps = {
  spaProps?: {
    employeeId: string
  }
  ec?: EcProps
} & BanquetProps

export const App = (props?: AppProps) => {
  const [client] = React.useState(() => getClient())
  const user = props?.ec?.user

  return !!user ? (
    <ApolloProvider client={client}>
      <AppContext user={user}>
        {/*React.Suspense is required to resolve the useFeature hook promise. Once this is no longer behind a feature flag, suspense can be removed*/}
        <React.Suspense>
          <AppRouter />
        </React.Suspense>
      </AppContext>
    </ApolloProvider>
  ) : (
    <EmptyState
      className='p-2'
      icon={<LockLockedIcon accessibility='decorative' />}
      title={t('no-user.title')}
    >
      {t('no-user.description')}
    </EmptyState>
  )
}
