import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const HealthBenefitsDisplayIcon = React.forwardRef(
  function HealthBenefitsDisplayIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={64}
              height={64}
              viewBox='0 0 64 64'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'xl'].className
              )}
            >
              <path
                d='M55.14 37.99V23.5H39.15V6.49h-16V23.5h-16v17h15.78v17.01h16.09'
                stroke='#FF4C00'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M48.17 41.58c0-1.77-1.79-2.87-4-2.87s-4 1.1-4 2.87c0 4 8 1.73 8 6 0 1.77-1.79 3-4 3s-4-1.23-4-3M44.17 36.58v2M44.17 50.58v2'
                stroke='#5069C3'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M48.17 41.58c0-1.77-1.79-2.87-4-2.87s-4 1.1-4 2.87c0 4 8 1.73 8 6 0 1.77-1.79 3-4 3s-4-1.23-4-3M44.17 36.58v2M44.17 50.58v2'
                stroke='#5069C3'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M44.17 57.32c7.003 0 12.68-5.677 12.68-12.68s-5.677-12.68-12.68-12.68-12.68 5.677-12.68 12.68 5.677 12.68 12.68 12.68z'
                stroke='#5069C3'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
