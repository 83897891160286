import * as React from 'react'
import { useDisclosure } from './useDisclosure'
import { Button, ButtonProps } from '@toasttab/buffet-pui-buttons'
import { ExpandIcon } from '@toasttab/buffet-pui-icons'

const defaultElement = 'button'

/**
 * Provides a wrapper around Button component that wires up the accessibility props from `useDisclosure`
 * and defaults to using ExpandIcon for the `iconRight` prop using the `isOpen` from `useDisclosure`.
 */
export function DisclosureToggleButton<
  TElementType extends React.ElementType = typeof defaultElement
>({
  variant = 'text-link',
  expandIconPosition = 'right',
  ...props
}: ButtonProps<TElementType> & {
  expandIconPosition?: 'right' | 'left' | 'none'
}) {
  const { isOpen, toggleProps } = useDisclosure()

  const icon = <ExpandIcon isExpanded={isOpen} accessibility='decorative' />

  return (
    <Button
      variant={variant}
      {...toggleProps}
      iconLeft={expandIconPosition === 'left' ? icon : undefined}
      iconRight={expandIconPosition === 'right' ? icon : undefined}
      {...(props as ButtonProps<TElementType>)}
    />
  )
}
