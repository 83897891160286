import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const HelpIcon = React.forwardRef(function HelpIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M12.4 16.8a.5.5 0 11-1 0 .5.5 0 011 0z'
              stroke='currentColor'
            />
            <path
              d='M12 21a9 9 0 100-18 9 9 0 000 18z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
            />
            <path
              d='M9.1 9.1s.5-2.6 2.8-2.6c.8 0 1.6.2 2.2.8.6.6.8 1.4.6 2.2-.2.6-.7 1-1.2 1.5-.7.7-1.6 1.5-1.6 2.5'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
