import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Button } from '@toasttab/buffet-pui-buttons'

export type HelpLink = {
  icon: React.ReactNode
  label: string
  href?: string
  newWindow?: boolean
  onClick?: VoidFunction
}

export type HelpLinkGroup = {
  label: string
  helpLinks: HelpLink[]
}

export type PayrollHelpSectionProps = {
  /* className to apply to the entire component */
  className?: string
  /* list of help link groups, each of which has a label and a list of help links */
  helpLinkGroups: HelpLinkGroup[]
  /* information about how to contact support and hours of operation */
  contactSupport: React.ReactNode
} & TestIdentifiable

/**
 * PayrollHelpSection
 * A component used to display help links and support contact info
 */
export const PayrollHelpSection: React.FunctionComponent<
  PayrollHelpSectionProps
> = ({ className, helpLinkGroups, ...rest }) => {
  return (
    <div className={cx('grid gap-y-4', className)}>
      {helpLinkGroups.map((group, groupIndex) => {
        return (
          <div
            className='grid gap-y-4 justify-items-start'
            key={`help-link-group-${groupIndex}`}
          >
            <span
              className='font-semibold type-default'
              role='heading'
              aria-level={2}
            >
              {group.label}
            </span>
            <div className='grid justify-items-start'>
              {group.helpLinks.map((helpLink, linkIndex) => {
                return (
                  <PayrollHelpLink
                    key={`help-link-group-${groupIndex}-link-${linkIndex}`}
                    {...helpLink}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
      <ContactSupportSection {...rest} />
    </div>
  )
}

const PayrollHelpLink: React.FunctionComponent<HelpLink> = ({
  href,
  icon,
  label,
  newWindow = false,
  onClick
}) => {
  return (
    <Button
      as='a'
      href={href}
      iconLeft={icon}
      onClick={onClick}
      target={newWindow ? '_blank' : undefined}
      variant='text-link'
    >
      {label}
    </Button>
  )
}

const ContactSupportSection: React.FunctionComponent<
  Pick<PayrollHelpSectionProps, 'contactSupport'>
> = ({ contactSupport }) => {
  return (
    <div className='text-secondary type-subhead'>
      <span>{contactSupport}</span>
    </div>
  )
}
