import * as React from 'react'
import { useUniqueId } from '@toasttab/buffet-utils'
import { DisclosureContext } from './useDisclosure'
import { DisclosureContent } from './DisclosureContent'
import { DisclosureToggleButton } from './DisclosureToggleButton'

export interface DisclosureProps {
  testId?: string | number
  id?: string // Note: id is required for a11y
  isOpen?: boolean
  /* If you want to use this component in an uncontrolled way but provide an initial state */
  initialIsOpen?: boolean
  onToggle?: (newIsOpen: boolean) => void
  className?: string
}

export const Disclosure = ({
  testId = 'disclosure',
  id,
  isOpen: externalIsOpen,
  onToggle: externalOnToggle,
  initialIsOpen = false,
  className,
  children
}: React.PropsWithChildren<DisclosureProps>) => {
  id = useUniqueId(id, 'disclosure-')
  const [internalIsOpen, setIsOpen] = React.useState(initialIsOpen)
  const isOpen = externalIsOpen !== undefined ? externalIsOpen : internalIsOpen

  const onToggle = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      const newIsOpen = !isOpen
      externalIsOpen === undefined && setIsOpen(newIsOpen)
      if (externalOnToggle) {
        externalOnToggle(newIsOpen)
      }
    },
    [externalOnToggle, isOpen, externalIsOpen]
  )

  return (
    <DisclosureContext.Provider
      value={{
        id,
        testId,
        isOpen,
        toggleProps: {
          id: `${id}-button`,
          'aria-controls': `${id}-content`,
          'aria-expanded': isOpen,
          'data-testid': `${testId}-button`,
          onClick: onToggle
        }
      }}
    >
      <section data-testid={testId} className={className}>
        {children}
      </section>
    </DisclosureContext.Provider>
  )
}

Disclosure.Content = DisclosureContent

Disclosure.Toggle = {
  Button: DisclosureToggleButton
}
