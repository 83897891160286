import * as React from 'react'

type ContextType = {
  id: string
  testId: string | number
  isOpen: boolean
  toggleProps: {
    id: string
    'aria-controls': string
    'aria-expanded': boolean
    'data-testid': string
    onClick: (event: React.MouseEvent) => void
  }
} | null

export const DisclosureContext = React.createContext<ContextType>(null)

export const useDisclosure = () => {
  const context = React.useContext(DisclosureContext)

  if (context == null) {
    throw new Error('Disclosure components must be wrapped in <Disclosure />')
  }

  return context
}
