import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const CommitmentAccountabilityIllustration = React.forwardRef(
  function CommitmentAccountabilityIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '346'}
              height={resizeToContainer ? '100%' : '291'}
              viewBox='0 0 346 291'
              fill='none'
              className='inline-block'
            >
              <path
                d='M343.71 50.69h-16.23M262.56 131.83v-6.19M262.56 2v16.22'
                stroke='#E3E3E3'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M278.79 2v48.69'
                stroke='#E3E3E3'
                strokeWidth={4}
                strokeLinecap='round'
              />
              <path
                d='M343.71 83.15h-16.23M295.02 131.84v-16.23M295.02 2v16.23M343.71 66.91h-48.69M278.79 131.84V114.7M316.66 29.05l-21.64 21.64M316.66 104.78l-21.64-21.63M262.56 50.69l-15.55-15.56'
                stroke='#E3E3E3'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M99.38 196.9H83.15M83.15 228.01v-15.58h16.23M83.15 165.14v16.22h16.23M2 263.87l32.46-32.46M2 130.63l32.46 32.46M66.92 263.19v-49.36M3.35 196.9h47.34M66.92 132.68v48.68M50.69 228.01v-15.58H34.46M50.69 165.14v16.22H34.46'
                stroke='#E3E3E3'
                strokeWidth={4}
                strokeLinecap='round'
              />
              <path
                d='M227.94 205.55c0 8.1 6.58 14.67 14.67 14.67-8.09 0-14.67 6.57-14.67 14.67 0-8.1-6.58-14.67-14.67-14.67 8.09 0 14.67-6.57 14.67-14.67zM57.25 24.52c0 8.1 6.58 14.67 14.67 14.67-8.09 0-14.67 6.57-14.67 14.67 0-8.1-6.58-14.67-14.67-14.67 8.09 0 14.67-6.57 14.67-14.67z'
                stroke='#E3E3E3'
                strokeWidth={4}
                strokeLinejoin='round'
              />
              <path
                d='M42.57 88.65c-2.03 0-3.67-1.64-3.67-3.67 0-2.03 1.64-3.67 3.67-3.67 2.03 0 3.67 1.64 3.67 3.67 0 2.03-1.64 3.67-3.67 3.67zM75.59 66.24c-2.03 0-3.67-1.64-3.67-3.67 0-2.03 1.64-3.67 3.67-3.67 2.03 0 3.67 1.64 3.67 3.67 0 2.03-1.64 3.67-3.67 3.67zM266.23 223.89c-2.03 0-3.67-1.64-3.67-3.67 0-2.03 1.64-3.67 3.67-3.67 2.03 0 3.67 1.64 3.67 3.67 0 2.03-1.64 3.67-3.67 3.67z'
                fill='#E3E3E3'
              />
              <path
                d='M204.68 95.72l24.19-24.19c6.14-6.14 16.19-6.14 22.34 0M100.74 288.51v-90.76c0-8.06 6.53-14.59 14.59-14.59h72.77v105.35'
                stroke='#BFBFBF'
                strokeWidth={4}
                strokeLinecap='round'
              />
              <path
                d='M113.02 183.16V155.9c0-19.07 11.71-25.3 23.68-25.87 8.75-.41 19.65-.8 19.65-.8l30.95-30.95s10.32-10.32 20.63 0c10.32 10.32 0 20.64 0 20.64l-30.95 30.95'
                stroke='#BFBFBF'
                strokeWidth={4}
                strokeLinecap='round'
              />
              <path
                d='M202.23 124.62l58.29-57.45c6.14-6.14 16.19-6.14 22.33 0 6.14 6.14 6.14 16.19 0 22.33l-34.53 35.15s-29.18 29.18-43.78 43.77c-14.59 14.59-43.77 14.87-43.77 14.87h-43.78M100.55 212.35h87.55'
                stroke='#BFBFBF'
                strokeWidth={4}
                strokeLinecap='round'
              />
              <path
                d='M151.6 51.63c0-8.06 6.81-14.59 14.61-14.59s14.59 6.53 14.59 14.59v7.3l-29.18 29.18-29.18-29.18v-7.3c0-8.06 6.79-14.59 14.59-14.59s14.57 6.53 14.57 14.59z'
                stroke='#FF4D00'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
