import * as React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  Page,
  PageBack,
  PageBody,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { BenefitsQueryFeinFragment } from '@local/grouter'
import { t } from '@local/translations'
import { BenefitsQuery } from './api-documents'
import { FeinsWithBenefitsCard } from './feins-with-benefits-card'
import { FeinsWithoutBenefitsCard } from './feins-without-benefits-card'
import { HelpSection } from './HelpSection'
import { InsuranceFlow } from '../third-part-data-sharing-consent-modal'
import { LoadingPage } from './LoadingPage'

export type GroupHealthPageProps = {
  insuranceFlow?: InsuranceFlow
}

export const GroupHealthPage: React.FunctionComponent<GroupHealthPageProps> = (
  props
) => {
  const { data, loading } = useQuery(BenefitsQuery)
  const allFeins: BenefitsQueryFeinFragment[] = data?.customer.feins || []
  const feinsWithBeneifts: BenefitsQueryFeinFragment[] = []
  const feinsWithoutBenefits: BenefitsQueryFeinFragment[] = []
  allFeins.forEach((fein) => {
    if (fein.benefits.length === 0) {
      feinsWithoutBenefits.push(fein)
    } else {
      feinsWithBeneifts.push(fein)
    }
  })

  return (
    <Page disableMaxWidth>
      <PageHeader>
        <Title>{t('group-health.title')}</Title>
        <PageBack as={Link} to='..' relative='path' />
      </PageHeader>
      <PageBody>
        <div className='flex flex-col gap-y-6 md:flex-row items-start'>
          <div className='grid gap-y-6 w-full md:w-3/4'>
            {loading ? (
              <LoadingPage />
            ) : (
              <>
                {feinsWithoutBenefits.length !== 0 && (
                  <PageSection title={t('group-health.setup.title')}>
                    <FeinsWithoutBenefitsCard
                      feins={feinsWithoutBenefits}
                      showFeinTable={allFeins.length > 1}
                      {...props}
                    />
                  </PageSection>
                )}
                <PageSection title={t('group-health.benefits.title')}>
                  <FeinsWithBenefitsCard feins={feinsWithBeneifts} />
                </PageSection>
              </>
            )}
          </div>
          <HelpSection />
        </div>
      </PageBody>
    </Page>
  )
}

type PageSectionProps = {
  title: string
}

const PageSection: React.FunctionComponent<
  React.PropsWithChildren<PageSectionProps>
> = ({ title, children }) => {
  return (
    <div className='grid gap-y-6'>
      <header className='font-semibold type-default px-4 md:px-0'>
        {title}
      </header>
      {children}
    </div>
  )
}
