import * as React from 'react'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { LockLockedIcon } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { t } from '@local/translations'

/**
 * NotAuthorizedState component
 */
export const NotAuthorizedState: React.FunctionComponent<TestIdentifiable> = (
  props
) => {
  return (
    <EmptyState
      className='p-2'
      icon={<LockLockedIcon accessibility='decorative' />}
      {...props}
    >
      {t('not-authorized')}
    </EmptyState>
  )
}
