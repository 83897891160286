import * as React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { FavoriteIcon } from '@toasttab/buffet-pui-icons'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { PayrollFein } from '@local/grouter'
import { t } from '@local/translations'
import {
  FeinsWithBenefitsTable,
  FeinWithBenefitsTableSmallScreen
} from './components'

export type FeinsWithBenefitsCardProps = {
  feins: Pick<PayrollFein, 'id' | 'name' | 'fein'>[]
}

export const FeinsWithBenefitsCard: React.FunctionComponent<
  FeinsWithBenefitsCardProps
> = ({ feins }) => {
  const isSmallScreen = useScreenSize() < ScreenSize.MD
  return (
    <CardContainer className='overflow-hidden' noElevation>
      {feins.length < 1 ? (
        <CardEmptyState />
      ) : isSmallScreen ? (
        <FeinWithBenefitsTableSmallScreen feins={feins} />
      ) : (
        <FeinsWithBenefitsTable feins={feins} />
      )}
    </CardContainer>
  )
}

const CardEmptyState: React.FunctionComponent = () => {
  return (
    <EmptyState
      icon={<FavoriteIcon accessibility='decorative' className='text-link' />}
    >
      {t('group-health.benefits.empty')}
    </EmptyState>
  )
}
