import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { t } from '@local/translations'
import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'

export const ManageBenefitsButton: React.FunctionComponent = () => {
  return (
    <Button
      iconRight={<ArrowForwardIcon accessibility='decorative' />}
      variant='text-link'
    >
      {t('group-health.benefits.button')}
    </Button>
  )
}
