import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const DocumentIcon = React.forwardRef(function DocumentIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M14.7 3H6.6a1.8 1.8 0 00-1.8 1.8v14.4A1.8 1.8 0 006.6 21h10.8a1.8 1.8 0 001.8-1.8V7.5L14.7 3zm0 0v4.955h4.5M12 11.682H7.311m3.19 2.657H7.31'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
