import * as React from 'react'
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider
} from 'react-router-dom'
import { DashboardPage } from './benefits-dashboard'
import { GroupHealthPage } from './group-health'

export const routes: RouteObject[] = [
  {
    path: 'Admin/Benefits/*', // don't take over the legacy route i.e. admin -> setup benefits -> basic life and disability -> basic life
    element: <></>
  },
  {
    path: 'reports/benefits/*', // don't take over the legacy route i.e. benefits -> reports -> employee benefits elections
    element: <></>
  },
  {
    path: '/:companyCode/benefits',
    element: <DashboardPage />
  },
  {
    path: '/:companyCode/benefits/add-deduction',
    element: <DashboardPage isDeductionModalOpen={true} />
  },
  {
    path: '/:companyCode/benefits/group-health',
    element: <GroupHealthPage />
  },
  {
    path: '/:companyCode/benefits/group-health/quote',
    element: <GroupHealthPage insuranceFlow='quote' />
  },
  {
    path: '/:companyCode/benefits/group-health/broker-of-record',
    element: <GroupHealthPage insuranceFlow='broker-of-record' />
  },
  {
    path: '/:companyCode/benefits/*',
    element: <Navigate to='.' replace />
  }
]

const router = createBrowserRouter(routes)

export const AppRouter = () => {
  return <RouterProvider router={router} />
}
