import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const HealthInsuranceDisplayIcon = React.forwardRef(
  function HealthInsuranceDisplayIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={64}
              height={64}
              viewBox='0 0 64 64'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'xl'].className
              )}
            >
              <path
                d='M32 54.19L6.64 28.83v-6.34c0-7 5.9-12.68 12.68-12.68 6.78 0 12.66 5.68 12.66 12.68M35.24 50.95L32 54.19M57.36 28.83l-2.99 2.99'
                stroke='#5069C3'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M31.98 22.49c0-7 5.91-12.68 12.7-12.68 6.79 0 12.68 5.68 12.68 12.68v6.34'
                stroke='#5069C3'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M50.97 54.19v-6.34h6.39v-6.34h-6.34v-6.34h-6.34v6.34h-6.34v6.34h6.25v6.34h6.38z'
                stroke='#FF4C00'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
