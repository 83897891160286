import * as React from 'react'
import { Modal, ModalProps } from '@toasttab/buffet-pui-modal'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Button } from '@toasttab/buffet-pui-buttons'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { t } from '@local/translations'

export type ErrorModalProps = {
  retryOnClick: VoidFunction
} & Pick<ModalProps, 'isOpen' | 'onRequestClose'> &
  TestIdentifiable

/**
 * Generic ErrorModal component
 */
export const ErrorModal: React.FunctionComponent<ErrorModalProps> = ({
  testId,
  retryOnClick,
  ...rest
}) => {
  return (
    <Modal size='xxl' testId={testId?.toString()} {...rest}>
      <Modal.Header />
      <Modal.Body>
        <div className='grid space-y-4 justify-items-center md:mx-44 mt-8 mb-10'>
          <WarningOutlineIcon accessibility='decorative' />
          <div className='text-center'>{t('error-modal.description')}</div>
          <Button className='mt-2' onClick={retryOnClick} variant='secondary'>
            {t('error-modal.button')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
