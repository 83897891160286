import { graphql } from '@local/grouter'

export const BenefitsQuery = graphql(`
  query BenefitsQuery {
    customer {
      feins {
        ...BenefitsQueryFein
      }
      id
      uuid
    }
  }
`)

graphql(`
  fragment BenefitsQueryFein on PayrollFein {
    benefits {
      benefitType
    }
    fein
    id
    name
  }
`)
