import * as React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'

export const LoadingPage: React.FunctionComponent = () => {
  return (
    <div
      className='w-full grid gap-y-8'
      data-testid='group-health-loading-page'
    >
      {/* page section title */}
      <Skeleton className='h-4 w-48 mx-4 md:mx-0' />
      <CardContainer testId='grouphealth-benefits-setup-loading'>
        <div className='grid gap-y-2'>
          <div className='flex flex-col md:flex-row gap-y-4 gap-x-2'>
            {/* image */}
            <Skeleton className='w-48 h-40' />
            <div className='w-full grid gap-y-2'>
              {/* panel title */}
              <Skeleton className='h-6' />
              <Skeleton className='h-6' />
              {/* panel description */}
              <Skeleton className='h-4' />
              <Skeleton className='h-4' />
              <div className='flex flex-col md:flex-row gap-y-3 gap-x-2'>
                {/* buttons */}
                <Skeleton className='h-12 md:h-10 w-full md:w-20' />
                <Skeleton className='h-12 md:h-10 w-full md:w-40' />
              </div>
            </div>
          </div>
          <DividingLine />
          {/* disclosure section */}
          <Skeleton className='h-4' />
        </div>
      </CardContainer>
    </div>
  )
}
