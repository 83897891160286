import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const LockLockedIcon = React.forwardRef(function LockLockedIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M17 21.05H7c-1.1 0-2-.9-2-2v-8c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2v8c0 1.1-.9 2-2 2zM16.3 8.55v-1.3c0-2.4-1.9-4.3-4.3-4.3-2.3.1-4.2 2-4.2 4.3v1.3'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M12 16.55a1.5 1.5 0 100-3 1.5 1.5 0 000 3z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
